<template>
    <OMediaQueryProvider v-slot="{ isTouch }">
        <div class="dropdown form-control text-nowrap" :class="[{ 'disabled': filterItem.excluded }, buttonClass]"
            style="max-width:min-content;" data-bs-toggle="dropdown" aria-expanded="false" v-if="isTouch">
            <div :id="id">
                {{ filterItem.dateExpression ? '&hellip;' : $t(filterItem.getOperatorTitle(filterItem.operator)) }}
            </div>
            <ul class="dropdown-menu p-0" style="max-height:200px;overflow-y:auto;" :aria-labelledby="id">
                <li class="dropdown-item" :class="{ 'active': filterItem.operator === op.name }"
                    @click="setOperator(op, isTouch)" v-for="op in filterItem.operators">
                    {{ $t(op.title) }}
                </li>
            </ul>
        </div>

        <div class="" v-else>
        
            <button class="btn btn-sm btn-link dropdown-toggle ps-0 ms-0 me-1" type="button" :id="id" data-bs-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false">
                {{ $t(filterItem.getOperatorTitle(filterItem.operator)) }}
            </button>
     
            <div class="dropdown-menu " :aria-labelledby="id">
                
                <button class="dropdown-item small" :class="{ 'active': filterItem.operator === op.name }"
                    @click="setOperator(op)" v-for="op in filterItem.operators">
                    {{ $t(op.title) }} 
                </button>
            </div>

     

        </div>
    </OMediaQueryProvider>
</template>

<script setup>
import { OMediaQueryProvider } from 'o365-ui-components';

const props = defineProps(['filterItem', 'filterObject', 'dropdown', 'buttonClass']);

const id = 'dropdownOperators_' + window.crypto.randomUUID();

if (!props.filterItem.operators.find(x => x.name === props.filterItem.operator) && props.filterItem.operator != 'exists_clause') {
    props.filterItem.operator = props.filterItem.defaultOperator;
}

const setOperator = (op, isTouch = false) => {
    // Ensure compatibility between operator swapping by resetting the item (and the data)
    if (isTouch) {
        props.filterItem.resetItem();
    }

    props.filterItem.operator = op.name;

    if (props.filterItem.isNullValueOperator) {
        props.filterItem.expressionValue = null;
    }

    if (isTouch) {
        props.filterObject.apply();
    }

    if (op.name == "inlist" && props.filterItem.expressionValue && props.filterItem.operator != 'exists_clause') {
        props.filterItem.selectedValue = props.filterItem.parseValue(props.filterItem.expressionValue).value;
    }

}
</script>
